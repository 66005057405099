import { ValueStore } from "./value-store";
export class ElementIdGenerator {
    static createId(self, name) {
        return this.createIdFromString(self.getAttribute("id"), name);
    }
    static createIdFromString(id, name) {
        if (id) {
            return `${id}-${name}`;
        }
        else {
            return undefined; // no id (random numbers interference with SlotObserver)
        }
    }
}
ElementIdGenerator.generateCounter = {};
ElementIdGenerator.unlabeledCounter = 1;
export function verifyElementId(element) {
    var _a;
    const idIsSet = ((_a = element.id) === null || _a === void 0 ? void 0 : _a.length) > 0;
    if (!idIsSet && !element.classList.contains(NO_ID_WARNING_CSS_CLASS)) {
        const store = ValueStore.instance();
        const suppressWarning = document.body.classList.contains(NO_ID_WARNING_CSS_CLASS);
        const suppressAll = document.body.classList.contains(SUPPRESS_ID_WARNING_CSS_CLASS);
        const generalNoticePrinted = store.getValue(GENERAL_NOTICE_PRINTED_STORAGE_KEY, suppressAll);
        if (!generalNoticePrinted) {
            store.setValue(GENERAL_NOTICE_PRINTED_STORAGE_KEY, true);
            if (!suppressWarning) {
                console.warn('Einige Formular-Elemente auf dieser Seite haben kein eindeutiges ID-Attribut erhalten. Diese sind für das Schreiben von automatisierten Tests essentiell. Details finden Sie in weiteren Warn-Meldungen.\n\nWarn-Meldungen können durch Setzen der CSS-Klasse "no-id-warning" auf dem BODY-Element unterdrückt werden.');
            }
            else {
                console.warn('Einige Formular-Elemente auf dieser Seite haben kein eindeutiges ID-Attribut erhalten. Weitere Meldungen wurden unterdrückt.\n\nUm die Unterdrückung aufzuheben, entfernen Sie die CSS-Klasse "no-id-warning" von dem BODY-Element.');
            }
        }
        if (!suppressAll && !suppressWarning) {
            console.warn("Das Element hat kein eindeutiges ID-Attribut: ", element);
        }
    }
}
const GENERAL_NOTICE_PRINTED_STORAGE_KEY = "id-warning-initial-message";
const NO_ID_WARNING_CSS_CLASS = "no-id-warning";
const SUPPRESS_ID_WARNING_CSS_CLASS = "suppress-id-warnings";
